import * as React from 'react';
import clsx from 'clsx';
import loadable from '@loadable/component';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
//styles
import styles from '../technologies.module.css';
//components
import Clutch from '../clutch';
import Achievements from 'components/recognition/achievements';
import WorkTypes from '../work-types';
import ReviewSlider1 from 'components/services/team-augmentation/review-slider1';
import ReviewSlider2 from 'components/services/team-augmentation/review-slider2';
import { UnderlineBullets } from 'components/bullets/titled-bullets';
import Awards from 'components/about-us/awards';
import Booking from 'components/feedback';
import OfferWidget from 'components/services/team-augmentation/offer-wiget';
import Layout from '../components/layout';
import {
  RevenueBoosters,
  Backbone,
  Gokada,
  BeyondGreen,
  KoroBox,
  LadderOut,
  BroCoders,
  Skilent,
  MyCurrency,
} from 'components/carousel';
import FAQ from 'components/faq';
//hooks
import { useFaqData } from 'hooks/useFaqData';
//meta
import identificators from 'components/googleAnalyticsIdentificators';
import { meta } from '../../../metaData';

const Routes = require('../../routes').Routes;

const benefitsList = [
  {
    title: 'Save time',
    text: 'Scale your team with our Node.js developers and save precious time. Hiring local engineers in the USA typically takes 2 months on average. It can cause a significant delay for projects with tight deadlines. In Brocoders, we provide the right backend talent within just 7 days.',
  },
  {
    title: 'Be flexible',
    text: 'Whether you need short-term or long-term support - hire our Node.js developers for the duration that suits your needs and eliminate the cost of full-time hiring. Feel free to extend your team whenever required and ensure project adaptability.',
  },
  {
    title: 'Pick out the best',
    text: "We want to choose the best fit for Node.js developers for your team. Let's start with a meeting to clarify project details and understand your unique requirements. Then we present you with qualified candidates, so you can personally vet and interview them. Select the perfect match for your project, and we will quickly assemble your efficient team.",
  },
  {
    title: 'Reduce costs',
    text: 'Partnering with an outsourced company brings you the advantage of cost reduction. By hiring our Node.js developers, you eliminate the expenses for recruitment, staff training, office space, energy bills, software licenses, employee benefits, payroll increments, and logistical costs. You only pay for the actual work on a project basis.',
  },
  {
    title: 'Meet deadlines',
    text: 'With our Node.js developers team, you can easily meet project deadlines. You can quickly hire and supervise employees to speed up the development process. Our developers are ready to start as soon as they join your team.',
  },
  {
    title: 'Meet Get your own team',
    text: 'Our Node.js developers integrate with your team and ensure seamless collaboration throughout the development process. You communicate directly and manage the workload of each developer. We take care of all paperwork, team setup and payroll operations, allowing you to focus on efficient management.',
  },
];

interface Props {
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));

function NodeJS({ location }: Props) {
  const faqData = useFaqData('allStrapiTechnology', 'Node.js');
  const data = useStaticQuery(graphql`
    query {
      qualityDeveloper: file(relativePath: { eq: "node-developer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="Hire Node.js Developers"
      subtitle="Employ the expertise of our Node.js developers to create highly efficient, scalable, fast, and real-time software products that empower your projects and delight customers."
      metaData={{ main: meta.nodejs, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
      image={<Clutch />}
    >
      <section className="section">
        <Achievements />
      </section>
      <ReviewSlider1 />
      <section className="section">
        <div className="inner">
          <h2 className={styles.title}>Our Node.js Expertise</h2>
          <p className={styles.lightPraragraph}>
            Expand your in-house team with our software engineers or let us build a cross-functional
            dedicated team for you.
          </p>
          <ul className={styles.expertiseList}>
            <li className={styles.expertiseItem} key="Streaming apps">
              <p className={styles.expertiseTitle}>Streaming apps</p>
              <p className={styles.expertiseText}>
                Optimize streaming content delivery to your users with the help of our Node.js
                developers. Leverage our expertise in building various streaming applications,
                including live video streaming platforms and music streaming services. Using
                Node.js, we allow users to download the core components first and access the rest
                software elements on-demand. Since the data is coming through a stream, we can
                process it in parallel during loading. With its streaming API, Node.js ensures
                efficient data streaming, reducing server and local computer overload.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Real-time chats">
              <p className={styles.expertiseTitle}>Real-time chats</p>
              <p className={styles.expertiseText}>
                Node.js, with its asynchronous and event-driven approach, is an excellent option for
                building real-time chats, which have become necessary for communication needs and
                business efficiency. Our Node.js developers specialize in building real-time chat
                apps to ensure seamless real-time communication and fast user response. We use a
                wide range of Node.js technologies and libraries to ensure reliable chat
                functionality: Socket.IO, Express.js, Redis, MongoDB, WebSockets, Pusher, Sails.js,
                Feather.js and others. We can combine and customize these technologies to meet the
                specific requirements of your real-time chat, messaging platform, live customer
                support or chatbots.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Complex SPA">
              <p className={styles.expertiseTitle}>Complex SPA</p>
              <p className={styles.expertiseText}>
                Employ our developers to handle Node.js as a backend for Single Page Applications
                (SPAs). With Node.js, you benefit from using a single language (JavaScript) for the
                entire project, the ability to share code and data structure between the frontend
                and backend apps, and the potential for server-side rendering. Furthermore, Node.js
                efficiently handles concurrent client requests and heavy input/output workloads. We
                have successfully implemented projects in various industries, such as{' '}
                <Link className="link" to={Routes.INDUSTRIES_AGRITECH}>
                  Agritech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_MARTECH}>
                  Martech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_PROPTECH}>
                  PropTech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_EDTECH}>
                  EdTech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_FINTECH}>
                  Fintech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.FITNESS_MOBILE_SOLUTIONS_B}>
                  Fitness & Healthcare
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_RIDESHARING}>
                  Ride-sharing
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_FOODTECH}>
                  Foodtech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_EVENTS_MANAGMENT}>
                  Events management
                </Link>
                , Recruting&HR, and E-commerce. We are proficient in integrating server-side logic
                with the client-side interface and deliver high-quality scalable complex SPAs.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Collaboration tools">
              <p className={styles.expertiseTitle}>Collaboration tools</p>
              <p className={styles.expertiseText}>
                Real-time collaboration is a crucial feature of modern applications. Whether you
                need coworking apps, project management platforms, video/audio conferencing tools,
                or collaborative document editing solutions, our Node.js developers have the
                expertise to create robust real-time collaboration tools. Node.js is a correct fit
                for building collaboration tools due to its asynchronous architecture and
                event-based API. Collaboration apps generate multiple events and I/O requests at
                once, so Node.js utilizes the WebSockets and Event API to prevent server hang-ups,
                ensuring efficient real-time data delivery.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <RevenueBoosters />
          <KoroBox />
          <LadderOut />
          <BroCoders />
          <Backbone />
          <Gokada />
          <BeyondGreen />
          <Skilent />
          <MyCurrency />
        </SwiperSlider>
      </section>

      <WorkTypes />

      <section className="section">
        <div className={styles.technicalAuditContainer}>
          <div className={styles.technicalAuditBox}>
            <h3 className={styles.technicalAuditTitle}>
              Hire Our Node.js Developers for Your Next Project
            </h3>
            <p className={styles.technicalAuditText}>
              Engage Brocoders as your Node.js development partner. Get access to our skilled
              developers, assess their expertise with detailed CVs, and expand your team with remote
              specialists. Our Node.js developers deliver top-quality solutions tailored to your
              needs. We offer benefits of outsourcing that optimize your budget without compromising
              on quality.
            </p>
            <a
              href="https://share.hsforms.com/1j39L9zW_QBiypvhPObxZ3Q4awz5"
              target="_blank"
              className={styles.technicalAuditLink}
            >
              get top node.js developers cv
            </a>
            <a
              id={identificators.GET_TECH_AUDIT}
              className="btn btn_60 btn__white wideBtn"
              href="https://share.hsforms.com/1j39L9zW_QBiypvhPObxZ3Q4awz5"
            >
              Request engineers you need
            </a>
          </div>
          <Img
            fluid={data.qualityDeveloper.childImageSharp.fluid}
            className={styles.technicalAuditImg}
            alt=""
          />
        </div>
      </section>
      <section className="section gray">
        <div className={clsx('inner-container', styles.marginBotom)}>
          <h2 className={styles.subTitle}>
            Why scale your team with our skilled Node.js Developers
          </h2>
          <p className={styles.lightPraragraph}>
            Benefit from our expertise for cost-efficiency, faster results, and scalability. Our
            Node.js developers deliver scalable network solutions, including full-stack development,
            cross-platform mobile apps, and API development. Choose Brocoders as your professional
            and reliable partner.
          </p>
          <UnderlineBullets list={benefitsList} />
        </div>
      </section>
      <section className={clsx('section', styles.negativeTop)}>
        <div className="inner">
          <FAQ data={faqData} />
        </div>
      </section>
      <ReviewSlider2 />
      <section className={clsx('section', styles.awardsContainer)}>
        <Awards isWithCaption containerStyles="inner" captionStyles={styles.title} />
      </section>

      <OfferWidget />
      <Booking />
    </Layout>
  );
}

export default NodeJS;
